import React from "react"
import Block from "../components/block"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "@mui/material"
import SubHeader from "../components/subHeader"
import Slider from "react-slick"
import Next from "../images/svgs/next.svg"
import Pre from "../images/svgs/pre.svg"
import ScrollAnimation from "react-animate-on-scroll"
import ContactFormSection from "../components/contactFormSection"
import InstagramSection from "../components/instagramSection"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Youtube from "../components/youtube"

const Course = ({ data }) => {
  const node = data.allWpCourse.edges[0].node
  const ytHeader = node.course.youtubeVideo.header
  const ytId = node.course.youtubeVideo.youtubeId
  function SampleNextArrow(props) {
    const { className, style, onClick } = props
    return <Next className={className} style={{ ...style }} onClick={onClick} />
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props
    return <Pre className={className} style={{ ...style }} onClick={onClick} />
  }

  // slider settings
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Layout>
      <Seo title={node?.headerPost.pageTitle} />
      <SubHeader
        title={node.headerPost.pageTitle}
        image={getSrc(node.headerPost.backgroundImage.localFile)}
      />
      {node.trainingCourses.block.map((item, index) => {
        return (
          <Block
            key={index}
            title={item.headerb}
            content={item.content}
            button1={item.button?.text}
            button1_link={item.button?.link}
            button2={item.buttonSecondary?.text}
            button2_link={item.buttonSecondary?.link}
            img={getSrc(item.image?.localFile)}
            imgAlt={item.image?.altText}
            inverted={item?.inverted}
          />
        )
      })}
      {node.course.gallery && (
        <section className="courseGallery">
          <Container>
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              <h2>Gallery</h2>
            </ScrollAnimation>
          </Container>
          <div className="courseSliderO">
            <div className="courseSliderP">
              <ScrollAnimation
                animateIn="animate__fadeIn"
                animateOnce={true}
                delay={100}
              >
                <Slider {...settings} className="courseSlider">
                  {node.course.gallery.map((slide, index) => {
                    return (
                      <div key={index} className="imageDiv">
                        <img
                          src={getSrc(slide.localFile)}
                          alt={slide.altText}
                        />
                      </div>
                    )
                  })}
                </Slider>
              </ScrollAnimation>
            </div>
          </div>
        </section>
      )}
      {(ytHeader || ytId) && (
        <section className="youtube">
          <Container>
            <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
              {ytHeader && <h2>Jandakot Heli-Co Trial Flight R22 </h2>}
            </ScrollAnimation>
            <div className="courseSliderO">
              <div className="courseSliderP">
                <ScrollAnimation
                  animateIn="animate__fadeIn"
                  animateOnce={true}
                  delay={100}
                >
                  {ytId && <Youtube embedId={"44cne84oCI8"} />}
                </ScrollAnimation>
              </div>
            </div>
          </Container>
        </section>
      )}

      <ContactFormSection />
      <InstagramSection />
    </Layout>
  )
}

export default Course

export const query = graphql`
  query ($id: String!) {
    allWpCourse(filter: { id: { eq: $id } }) {
      edges {
        node {
          headerPost {
            pageTitle
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
          }
          course {
            gallery {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100)
                }
              }
            }
            youtubeVideo {
              header
              youtubeId
            }
          }
          trainingCourses {
            block {
              headerb
              content
              button {
                link
                text
              }
              buttonSecondary {
                link
                text
              }
              inverted
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
